// don't arrange TagName in order because need to display tags in groups of 2 columns
export enum TagName {
  ManuallyCreated,
  SingleShipment = 3,
  FromComment = 1,
  MultiShipment = 4,
  FromCatalogue = 2,
  ActionRequired = 5,
}

export const tagNameToDisplay = (tagName: TagName): string => {
  switch (tagName) {
    case TagName.ManuallyCreated:
      return 'manually_created';
    case TagName.FromComment:
      return 'from_comment';
    case TagName.FromCatalogue:
      return 'from_catalogue';
    case TagName.SingleShipment:
      return 'single_shipment';
    case TagName.MultiShipment:
      return 'multi_shipments';
    case TagName.ActionRequired:
      return 'action_required';
    default:
      return 'UNKNOWN TAG';
  }
};

export default TagName;
