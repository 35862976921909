import { omit } from 'lodash';
import {
  CURRENT_ITEMS_SOLD_FILTER,
  EXPAND_PERFORMANCE_SECTION,
  FEATURE_GUIDE,
  NOTIFIED_NEW_UPDATES,
  PAGE_TOKEN_EXPIRED_MODAL,
  PIN_CODE,
  SALE_REPORT_DOWNLOADED,
  SHOULD_SHOW_DELIVERY_ONBOARDING,
  SHOW_HIDE_COLUMNS,
} from './constants';

export const setTaglishToggleStatus = (status: boolean) => {
  localStorage.setItem('isTaglishOn', status + '');
};

export const getTaglishToggleStatus = () => {
  return localStorage.getItem('isTaglishOn') === 'true';
};

export const getDownloadedSaleReport = () => {
  return localStorage.getItem(SALE_REPORT_DOWNLOADED);
};

export const setDownloadedSaleReport = (id: string) => {
  localStorage.setItem(SALE_REPORT_DOWNLOADED, id);
};

export const removeDownloadedSaleReport = () => {
  localStorage.removeItem(SALE_REPORT_DOWNLOADED);
};

export const getShowDeliveryOnboardingStatus = () => {
  return localStorage.getItem(SHOULD_SHOW_DELIVERY_ONBOARDING);
};

export const setShowDeliveryOnboardingStatus = (status: string) => {
  localStorage.setItem(SHOULD_SHOW_DELIVERY_ONBOARDING, status);
};

export const shouldShowDeliveryOnboarding = () => {
  const status = getShowDeliveryOnboardingStatus();
  return !status || status !== 'true';
};

export const getAllNotifiedNewUpdates = () => {
  return JSON.parse(localStorage.getItem(NOTIFIED_NEW_UPDATES) || '{}');
};

export const getNotifiedNewUpdates = (key: string) => {
  const values = getAllNotifiedNewUpdates();
  return values?.[key] || null;
};

export const setNotifiedNewUpdates = (value: { [key: string]: boolean }) => {
  const values = getAllNotifiedNewUpdates();
  localStorage.setItem(NOTIFIED_NEW_UPDATES, JSON.stringify({ ...values, ...value }));
};

export const getAllShowHideColumns = () => {
  return JSON.parse(localStorage.getItem(SHOW_HIDE_COLUMNS) || '{}');
};

export const getShowHideColumns = (key: string) => {
  const values = getAllShowHideColumns();
  return values?.[key] || null;
};

export const setShowHideColumns = (value: { [key: string]: string[] }) => {
  const values = getAllShowHideColumns();
  localStorage.setItem(SHOW_HIDE_COLUMNS, JSON.stringify({ ...values, ...value }));
};

export const getAllPinCode = () => {
  return JSON.parse(localStorage.getItem(PIN_CODE) || '{}');
};

export const getPinCode = (key: string) => {
  const values = getAllPinCode();
  return values?.[key] || null;
};

export const setPinCode = (value: { [key: string]: string }) => {
  const values = getAllPinCode();
  const data = { ...values, ...value };
  localStorage.setItem(PIN_CODE, JSON.stringify(data));
};

export const removePinCode = (key: string) => {
  const values = getAllPinCode();
  const data = omit(values, key);
  localStorage.setItem(PIN_CODE, JSON.stringify(data));
};

export const shouldExpandPerformanceSection = (key: string) => {
  const values = JSON.parse(localStorage.getItem(EXPAND_PERFORMANCE_SECTION) || '{}');
  return typeof values?.[key] === 'boolean' ? !!values?.[key] : true;
};

export const setExpandPerformanceSection = (value: { [key: string]: boolean }) => {
  const values = JSON.parse(localStorage.getItem(EXPAND_PERFORMANCE_SECTION) || '{}');
  localStorage.setItem(EXPAND_PERFORMANCE_SECTION, JSON.stringify({ ...values, ...value }));
};

export const getSeePageTokenExpiredModal = () => {
  return !!localStorage.getItem(PAGE_TOKEN_EXPIRED_MODAL);
};

export const setSeePageTokenExpiredModal = () => {
  localStorage.setItem(PAGE_TOKEN_EXPIRED_MODAL, 'true');
};

export const removeSeePageTokenExpiredModal = () => {
  localStorage.removeItem(PAGE_TOKEN_EXPIRED_MODAL);
};

//count from 0 to n
export const getSeeFeatureGuide = (time: number) => {
  const key = `${FEATURE_GUIDE}_${time}`;
  const prevkey = time - 1 === 0 ? FEATURE_GUIDE : `${FEATURE_GUIDE}_${time - 1}`;
  localStorage.removeItem(prevkey);
  return localStorage.getItem(key) ? Number(localStorage.getItem(key)) : 0;
};

export const setSeeFeatureGuide = (step: number, time: number) => {
  const key = `${FEATURE_GUIDE}_${time}`;
  localStorage.setItem(key, step.toString());
};

export const getCurrentItemsSoldFilter = (key: string) => {
  const values = JSON.parse(localStorage.getItem(CURRENT_ITEMS_SOLD_FILTER) || '{}');
  return values?.[key];
};

export const setCurrentItemsSoldFilter = (value: { [key: string]: any }) => {
  const values = JSON.parse(localStorage.getItem(CURRENT_ITEMS_SOLD_FILTER) || '{}');
  localStorage.setItem(CURRENT_ITEMS_SOLD_FILTER, JSON.stringify({ ...values, ...value }));
};
