import { lazy } from 'utils/route';

const Analytics = lazy(() => import('./Analytics'));
const CardTransactions = lazy(() => import('./CardTransactions'));
const CatalogueManagement = lazy(() => import('./CatalogueManagement'));
const CreditManagement = lazy(() => import('./CreditManagement'));
const DeliverySettings = lazy(() => import('./DeliverySettings'));
const CreateUpdateCustomDelivery = lazy(() => import('./DeliverySettings/CreateUpdateCustomDelivery'));
const FinancialAccount = lazy(() => import('./FinancialAccount/FinancialAccount'));
const FulfillOrders = lazy(() => import('./FulfillOrders'));
const FulfillProcess = lazy(() => import('./FulfillProcess'));
const MassFulfillProcessVN = lazy(() => import('./FulfillProcess/MassFulfillProcessVN'));
const LikerDraw = lazy(() => import('./LuckyDraw/LikerDraw'));
const LuckyDraw = lazy(() => import('./LuckyDraw/LuckyDraw'));
const PurchaserDraw = lazy(() => import('./LuckyDraw/PurchaserDraw'));
const Memberships = lazy(() => import('./Memberships'));
const OrderManagement = lazy(() => import('./PaidOrderManagement'));
const GenerateReport = lazy(() => import('./GenerateReport'));
const PackingListReport = lazy(() => import('./GenerateReport/Reports/PackingListReport'));
const FulfillmentReport = lazy(() => import('./GenerateReport/Reports/FulfillmentReport'));
const QuantitySoldReport = lazy(() => import('./GenerateReport/Reports/QuantitySoldReport'));
const TransactionSaleReport = lazy(() => import('./GenerateReport/Reports/TransactionSaleReport'));
const FillupPurchaseOrderReport = lazy(() => import('./GenerateReport/Reports/FillupPurchaseOrderReport'));
const ReportDetail = lazy(() => import('./GenerateReport/ReportDetail'));
const PaymentSettings = lazy(() => import('./PaymentSettings'));
const CartManagement = lazy(() => import('./PendingOrderManagement'));
const UsersPermission = lazy(() => import('./Permissions'));
const PickupPointList = lazy(() => import('./PickupPointChosen'));
const PostManagement = lazy(() => import('./PostManagement'));
const LiveSelling = lazy(() => import('./LiveSelling'));
const PostTemplates = lazy(() => import('./PostTemplates'));
const PriceDrop = lazy(() => import('./PriceDrop'));
const ProductGroupManagement = lazy(() => import('./ProductGroupManagement'));
const ProductLogManagement = lazy(() => import('./ProductLogManagement'));
const ProductManagement = lazy(() => import('./ProductManagement'));
const ProductSearch = lazy(() => import('./ProductSearch'));
const ProfileSettings = lazy(() => import('./ProfileSettings'));
const Promotions = lazy(() => import('./Promotions'));
const Settings = lazy(() => import('./Settings'));
const BundleDealManagement = lazy(() => import('./BundleDealManagement/BundleDealManagement'));
const CreateUpdateBundleDeal = lazy(() => import('./BundleDealManagement/CreateUpdateBundleDeal'));
const ViewBundleDealScreen = lazy(() => import('./BundleDealManagement/ViewBundleDealScreen'));
const ShipmentDetail = lazy(() => import('./ShipmentDetail'));
const ShippingChannelList = lazy(() => import('./ShippingChannel'));
const CreateUpdateProduct = lazy(() => import('./CreateUpdateProduct'));
const ShippingSettings = lazy(() => import('./ShippingSettings'));
const ChatManagement = lazy(() => import('./ChatManagement'));
const CreateUpdatePromotion = lazy(() => import('./Promotions/CreateUpdatePromotion'));
const ViewPromotion = lazy(() => import('./Promotions/ViewPromotion'));
const DuringLiveStream = lazy(() => import('./LiveSelling/DuringLiveStream'));
const QuickCreateProduct = lazy(() => import('./QuickCreateProduct'));
const AfterLiveStream = lazy(() => import('./LiveSelling/AfterLiveStream'));
const SetupLive = lazy(() => import('./LiveSelling/SetupLive'));
const ShipmentDetailVN = lazy(() => import('./PaidOrderManagement/ShipmentDetail'));
const MessageManagement = lazy(() => import('./MessageManagement'));
const ReplyComment = lazy(() => import('./ReplyComment'));
const InvoiceDisplay = lazy(() => import('./InvoiceDisplay'));
const BlackList = lazy(() => import('./Blacklist'));
const ItemsSold = lazy(() => import('./ItemsSold/ItemsSold'));

export const RoutesGroup = {
  CartService: [
    {
      path: '/carts',
      component: CartManagement,
    },
    {
      path: '/orders',
      component: OrderManagement,
    },
    {
      path: '/product-log',
      component: ProductLogManagement,
    },
    {
      path: '/items-sold',
      component: ItemsSold,
    },
  ],
  DeliveryService: [
    {
      path: '/delivery',
      component: DeliverySettings,
    },
    {
      path: '/create-custom-delivery',
      component: CreateUpdateCustomDelivery,
    },
    {
      path: '/edit-custom-delivery/:id',
      component: CreateUpdateCustomDelivery,
    },
    {
      path: '/logistic',
      component: PickupPointList,
    },
    {
      path: '/shipment/:id',
      component: ShipmentDetail,
    },
    {
      path: '/shipping-detail/:id',
      component: ShipmentDetailVN,
    },
    {
      path: '/failed/:id',
      component: ShipmentDetail,
    },
    {
      path: '/cancelled/:id',
      component: ShipmentDetail,
    },
    {
      path: '/delivered/:id',
      component: ShipmentDetail,
    },
    {
      path: '/fulfill-orders',
      component: FulfillOrders,
      exact: true,
    },
    {
      path: '/fulfill/:cartId/:orderId',
      component: FulfillProcess,
    },
    {
      path: '/mass-fulfill',
      component: MassFulfillProcessVN,
    },
    {
      path: '/fulfill/:id',
      component: FulfillProcess,
    },
    {
      path: '/shippingChannel',
      component: ShippingChannelList,
    },
    {
      path: '/shippingSettings',
      component: ShippingSettings,
    },
  ],
  FacebookService: [
    {
      path: '/dashboard',
      component: PostManagement,
    },
    {
      path: '/live-sessions/during-livestream/:id',
      component: DuringLiveStream,
    },
    {
      path: '/live-sessions/after-livestream/:id',
      component: AfterLiveStream,
    },
    {
      path: '/live-sessions/setup/:id',
      component: SetupLive,
    },
    {
      path: '/live-sessions/setup',
      component: SetupLive,
    },
    {
      path: '/live-sessions',
      component: LiveSelling,
    },
    {
      path: '/price-drop',
      component: PriceDrop,
    },
    {
      path: '/messages',
      component: MessageManagement,
    },
    {
      path: '/reply-comment',
      component: ReplyComment,
    },
    {
      path: '/blacklist',
      component: BlackList,
    },
  ],
  ProductService: [
    {
      path: '/products',
      component: ProductManagement,
    },
    {
      path: '/product/create',
      component: CreateUpdateProduct,
    },
    {
      path: '/product/edit/:id',
      component: CreateUpdateProduct,
    },
    {
      path: '/product-search',
      component: ProductSearch,
    },
    {
      path: '/product-groups',
      component: ProductGroupManagement,
    },
    {
      path: '/catalogue',
      component: CatalogueManagement,
    },
    {
      path: '/post-templates',
      component: PostTemplates,
    },
    {
      path: '/product/quick-create',
      component: QuickCreateProduct,
    },
  ],
  ProfileService: [
    {
      path: '/profile',
      component: ProfileSettings,
    },
    {
      path: '/payment',
      component: PaymentSettings,
    },
    {
      path: '/settings',
      component: Settings,
    },
    {
      path: '/invoice-display',
      component: InvoiceDisplay,
    },
  ],
  PromotionService: [
    {
      path: '/promotion',
      component: Promotions,
    },
    {
      path: '/create-promotion',
      component: CreateUpdatePromotion,
    },
    {
      path: '/edit-promotion/:id',
      component: CreateUpdatePromotion,
    },
    {
      path: '/view-promotion/:id',
      component: ViewPromotion,
    },
    {
      path: '/bundle-deals',
      component: BundleDealManagement,
    },
    {
      path: '/create-bundle',
      component: CreateUpdateBundleDeal,
    },
    {
      path: '/edit-bundle/:id',
      component: CreateUpdateBundleDeal,
    },
    {
      path: '/view-bundle/:id',
      component: ViewBundleDealScreen,
    },
    {
      path: '/lucky-draw/purchaser/:id',
      component: PurchaserDraw,
      exact: true,
    },
    {
      path: '/lucky-draw/liker/:id',
      component: LikerDraw,
      exact: true,
    },
    {
      path: '/lucky-draw/purchaser',
      component: PurchaserDraw,
      exact: true,
    },
    {
      path: '/lucky-draw/liker',
      component: LikerDraw,
      exact: true,
    },
    {
      path: '/lucky-draw',
      component: LuckyDraw,
      exact: true,
    },
    {
      path: '/memberships',
      component: Memberships,
    },
    {
      path: '/credit',
      component: CreditManagement,
    },
  ],
  UserService: [
    {
      path: '/permissions',
      component: UsersPermission,
    },
    {
      path: '/chat',
      component: ChatManagement,
    },
  ],
  PaymentService: [
    {
      path: '/financial-account',
      component: FinancialAccount,
    },
    {
      path: '/card-transactions',
      component: CardTransactions,
    },
  ],
  StatisticService: [
    {
      path: '/reports/packing-list',
      component: PackingListReport,
    },
    {
      path: '/reports/fulfillment',
      component: FulfillmentReport,
    },
    {
      path: '/reports/quantity-sold',
      component: QuantitySoldReport,
    },
    {
      path: '/reports/transaction-sale',
      component: TransactionSaleReport,
    },
    {
      path: '/reports/fillup-purchase-order',
      component: FillupPurchaseOrderReport,
    },
    {
      path: '/reports/:id',
      component: ReportDetail,
    },
    {
      path: '/reports',
      component: GenerateReport,
      exact: true,
    },
  ],
  AnalyticsFeature: [
    {
      path: '/analytics',
      component: Analytics,
    },
  ],
  CrawlerService: [],
  SubscriptionFeature: [],
  ExportOrderFeature: [],
  ExportProductFeature: [],
  ProductCostFeature: [],
};
